import './../index.css';
import { connect } from "react-redux";
import { ethers } from 'ethers';
import React, { useEffect, useState } from "react";
import myEpicNft from './../utils/MyEpicNFT.json';

import hike1 from './../assets/hike1.jpg';
import hike2 from './../assets/hike2.jpg';
import hike3 from './../assets/hike3.jpg';
import hike4 from './../assets/hike4.jpg';
import hike5 from './../assets/hike5.jpg';
import kayak1 from './../assets/kayak1.jpg';
import daudo from './../assets/Daudo Logo.png';

const YourDistance = ({ user, activities, returnTokens }) => {
  console.log("in your distance");
  console.log(user);
  console.log(activities);
  console.log(returnTokens);

  const nftImages = [
    hike1, hike2, hike3, hike4, hike5, kayak1
  ];

  const [currentAccount, setCurrentAccount] = useState("");

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum object", ethereum);
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  }

  /*
  * Implement your connectWallet method here
  */
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      /*
      * Fancy method to request access to account.
      */
      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      /*
      * Boom! This should print out public address once we authorize Metamask.
      */
      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const askContractToMintNft = async (nameAct, sport_type, elapsed_time, act_distance, act_calories, img_link) => {
    const CONTRACT_ADDRESS = "0x95D3D1F7Bae578f1C5DF764D20f7eD960A22D6A7";
    connectWallet();

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, myEpicNft.abi, signer);

        console.log("Going to pop wallet now to pay gas...");
        const selected_img_idx = Math.floor(Math.random() * nftImages.length);
        const new_img_link = img_link ? img_link : nftImages[selected_img_idx];
        let nftTxn = await connectedContract.makeAnEpicNFT(
          nameAct, sport_type, elapsed_time, act_distance, act_calories, new_img_link
        );

        console.log("Mining...please wait.")
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://polygonscan.com/tx/${nftTxn.hash}`);

      } else {
        console.log("Polygon object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Render Methods
  const renderNotConnectedContainer = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">
      Connect to Wallet
    </button>
  );


  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <div className="logo-div">
            <img src={daudo} alt="" className="logo" />
          </div>
          <p className="header gradient-text">Hi, {returnTokens.athlete.firstname}!</p>
          <p className="header gradient-text">Mint  an NFT from your recent activities</p>
          <p>Free to Mint, only pay for Gas Fee</p>
          <button className="cta-button connect-wallet-button" onClick={() => connectWallet()}> Connect Wallet Before Minting </button>

          <ul className="cards">
            {activities.map((item, i) => {
              console.log("index of item", i);
              const img_link = item.data.photos.primary ? item.data.photos.primary.urls['600'] : null;
              const selected_img_idx = Math.floor(Math.random() * nftImages.length);
              const new_img_link = img_link ? img_link : nftImages[selected_img_idx];
              const nameAct = item.data.name;
              const sport_type = item.data.sport_type;
              const elapsed_time = (item.data.elapsed_time / 60).toFixed(2);
              const act_distance = String(item.data.distance);
              const act_calories = String(item.data.calories);
              return (
                <li>
                  <a href="#" className="card">
                    <img src={new_img_link} className="card__image" alt="" />
                    <div className="card__overlay">
                      <div className="card__header">
                        <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                        <div className="card__header-text">
                          <h3 className="card__title"> {nameAct} </h3>
                          <span className="card__status">Sport: {sport_type}, Time to complete: {elapsed_time}, Distance: {act_distance}, Calories Burned: {act_calories}</span>
                          <button className="cta-button mint-button" onClick={() => askContractToMintNft(nameAct, sport_type, elapsed_time, act_distance, act_calories, img_link)}> Mint NFT </button>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div >
  );
};

const mapStateToProps = (state) => {
  console.log("this is the react state", state)
  return {
    user: state.user,
    activities: state.activities,
    returnTokens: state.returnTokens
  };
};

export default connect(mapStateToProps)(YourDistance);
