import axios from "axios";

const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET } = process.env;

export const getParamValues = (url) => {
  return url
    .slice(1)
    .split("&")
    .reduce((prev, curr) => {
      const [title, value] = curr.split("=");
      prev[title] = value;
      return prev;
    }, {});
};

export const cleanUpAuthToken = (str) => {
  return str.split("&")[1].slice(5);
};

export const testAuthGetter = async (authTok) => {
  try {
    const response = await axios.post(
      `https://www.strava.com/api/v3/oauth/token?client_id=94371&client_secret=f874d8a6ed29e29f1c5a9eb4e2270046c0d6b4d6&code=329c0a3d4661f87ab336f41fd3fa6f58c02b684a&code=${authTok}&grant_type=authorization_code`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserData = async (userID, accessToken) => {
  try {
    const response = await axios.get(
      `https://www.strava.com/api/v3/athletes/${userID}/stats`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUserActivities = async (userID, accessToken) => {
  try {
    const detailed_activities = [];
    const response = await axios.get(
      `https://www.strava.com/api/v3/athlete/activities`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    for (const activity of response.data.slice(0, 5)) {
      console.log(activity);
      try {
        const act_response = await axios.get(`https://www.strava.com/api/v3/activities/${activity.id}?include_all_efforts="`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        detailed_activities.push(act_response);
      } catch (error) {
        console.log(error);
      }
    }
    return detailed_activities;
  } catch (error) {
    console.log(error);
  }
};
