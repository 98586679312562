import React from "react";
import './../index.css';
import twitterLogo from './../assets/twitter-logo.svg';

import hike1 from './../assets/hike1.jpg';
import hike2 from './../assets/hike2.jpg';
import hike3 from './../assets/hike3.jpg';
import hike4 from './../assets/hike4.jpg';
import hike5 from './../assets/hike5.jpg';
import kayak1 from './../assets/kayak1.jpg';
import daudo from './../assets/Daudo Logo.png';

//const { REACT_APP_CLIENT_ID } = process.env;
const redirectUrl = "https://daudo.xyz/redirect"
const TWITTER_HANDLE = 'ishaan_jaff';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

const handleLogin = () => {
  window.location = `http://www.strava.com/oauth/authorize?client_id=94371&response_type=code&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=force&scope=read,activity:read_all`;
};

const Home = () => {
  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <div className="logo-div">
            <img src={daudo} alt="" className="logo" />
          </div>
          <p className="header gradient-text">Build your Strava Activity NFT Collection</p>
          <p className="sub-text">
            Capture your Best Workouts, Hikes, Runs</p>
          <p className="sub-text">
            Mint Cost: 0.0 ETH, only pay for Gas </p>
          <button onClick={handleLogin} className="cta-button connect-wallet-button">Connect with Strava </button>
        </div>


        <ul className="cards">
          <li>
            <a href="" className="card">
              <img src={hike1} className="card__image" alt="" />
              <div className="card__overlay">
                <div className="card__header">
                  <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                  <div className="card__header-text">
                    <h3 className="card__title">Evening Hike NFT</h3>
                    <span className="card__status">6 miles, 2 hrs, Yosemite, Avg Heartrate: 140 bpm </span>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="" className="card">
              <img src={hike3} className="card__image" alt="" />
              <div className="card__overlay">
                <div className="card__header">
                  <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                  <div className="card__header-text">
                    <h3 className="card__title">100m Freestyle world record</h3>
                    <span className="card__status">Morning Swim, Spain, 50 seconds, National Record</span>
                  </div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="" className="card">
              <img src={hike2} className="card__image" alt="" />
              <div className="card__overlay">
                <div className="card__header">
                  <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>

                  <div className="card__header-text">
                    <h3 className="card__title">Mt. Diablo Hike</h3>
                    <span className="card__status"> 5000 ft elevation gain, 10 miles, 3 hours</span>
                  </div>
                </div>

              </div>
            </a>
          </li>

          <li>
            <a href="" className="card">
              <img src={hike4} className="card__image" alt="" />
              <div className="card__overlay">
                <div className="card__header">
                  <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                  <div className="card__header-text">
                    <h3 className="card__title"> Evening Weightlifting </h3>
                    <span className="card__status">Back & Biceps, Moderate Effort, Avg Heartrate 130 bpm</span>
                  </div>
                </div>
              </div>
            </a>
          </li>

          <li>
            <a href="" className="card">
              <img src={kayak1} className="card__image" alt="" />
              <div className="card__overlay">
                <div className="card__header">
                  <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                  <div className="card__header-text">
                    <h3 className="card__title"> Islands Kayak NFT </h3>
                    <span className="card__status"> Calories 500 kcal, Avg Heartrate: 125 bpm</span>
                  </div>
                </div>
              </div>
            </a>
          </li>

          <li>
            <a href="" className="card">
              <img src={hike5} className="card__image" alt="" />
              <div className="card__overlay">
                <div className="card__header">
                  <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                  <div className="card__header-text">
                    <h3 className="card__title"> Sunset Hike</h3>
                    <span className="card__status"> Elevation 1000ft, 2 miles, 200 kcal</span>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>





      </div>

      <div className="footer-container mb-4">
        <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
        <a
          className="footer-text"
          href={TWITTER_LINK}
          target="_blank"
          rel="noreferrer"
        >{`built by @${TWITTER_HANDLE}`}</a>
      </div>
    </div>
  );
};

export default Home;